
/* layout */
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  

/* Parallax Styles */
.parallax-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  perspective: 8px;
  perspective-origin: 0%;
  display: flex;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0px);
}

.foreground {
  margin-top: auto;
  transform-origin: 0;
  transform: translateZ(3px) scale(0.625);
}
