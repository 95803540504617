.is-not-visible {
	transition: opacity 0.5s ease;
	opacity: 0;
}

.is-visible {
	transition: opacity 0.5s ease;
	opacity: 1;
}

.appear-left-invisible {
	transform: translateX(-30px) scale(0.9);
	opacity: 0;
}

.appear-right-invisible {
	transform: translateX(30px) scale(0.9);
	opacity: 0;
}

.appear-left {
	animation-name: opacity, appearLeft;
}

.appear-right {
	animation-name: opacity, appearRight;
}

.appear-left,
.appear-right {
	animation-duration: 1s;
	animation-timing-function: cubic-bezier(0.7, 0.1, 0.3, 1.1);
}

@keyframes appearLeft {
	0% {
		transform: translateX(-30px) scale(0.9);
	}

	100% {
		transform: translateX(0) scale(1);
	}
}

@keyframes appearRight {
	0% {
		transform: translateX(30px) scale(0.9);
	}

	100% {
		transform: translateX(0) scale(1);
	}
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bounceLeft {
	0% {
		transform: translateX(-30px);
	}

	25% {
		transform: translateX(-30px);
	}

	75% {
		transform: translateX(10px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes bounceRight {
	0% {
		transform: translateX(30px);
	}

	25% {
		transform: translateX(30px);
	}

	75% {
		transform: translateX(-10px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes maxHeight {
	0% {
		max-height: 0px;
	}

	100% {
		max-height: 300px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}


.fromTopIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, 0);
	opacity: 1;
}

.fromTopOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, -10px);
	opacity: 0;
}

.fromBottomIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, 0);
	opacity: 1;
}

.fromBottomOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, 10px);
	opacity: 0;
}

.fromLeftIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, 0);
	opacity: 1;
}

.fromLeftOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(-10px, 0);
	opacity: 0;
}

.fromRightIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(0, 0);
	opacity: 1;
}

.fromRightOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: translate(10px, 0);
	opacity: 0;
}

.scaleUpIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: scale(1, 1);
	opacity: 1;
}

.scaleUpOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: scale(1.1, 1.1);
	opacity: 0;
}

.scaleDownIn {
	transition: transform 1s ease, opacity 1s ease;

	transform: scale(1, 1);
	opacity: 1;
}

.scaleDownOut {
	transition: transform 1s ease, opacity 1s ease;

	transform: scale(0.9, 0.9);
	opacity: 0;
}

.staggered {
	>*:nth-child(1) {
		transition-delay: 0ms;
	}

	>*:nth-child(2) {
		transition-delay: 100ms;
	}

	>*:nth-child(3) {
		transition-delay: 200ms;
	}

	>*:nth-child(4) {
		transition-delay: 300ms;
	}

	>*:nth-child(5) {
		transition-delay: 400ms;
	}

	>*:nth-child(6) {
		transition-delay: 500ms;
	}

	>*:nth-child(7) {
		transition-delay: 600ms;
	}

	>*:nth-child(8) {
		transition-delay: 700ms;
	}

	>*:nth-child(9) {
		transition-delay: 800ms;
	}

	>*:nth-child(10) {
		transition-delay: 900ms;
	}

	>*:nth-child(11) {
		transition-delay: 1000ms;
	}

	>*:nth-child(12) {
		transition-delay: 1100ms;
	}
}