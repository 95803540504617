.button {
    @apply font-sans tracking-widest text-sm px-4 py-3 rounded select-none whitespace-no-wrap no-underline;
}

.button-light {
    @apply border-matisse-500 bg-blueGray-50 text-matisse-500;
    transition: all .3s ease;
}

.button-light:hover {
    @apply bg-matisse-500 text-blueGray-50;
}

.button-dark {
    @apply bg-matisse-400 text-blueGray-100 rounded p-2 font-bold;
    transition: all .3s ease;
}

.button-dark:hover {
    @apply bg-matisse-300 text-blueGray-50;
}