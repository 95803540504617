@font-face {
    font-family: "BankerSquare";
    font-style: normal;
    font-weight: normal;
    src: local("BankerSquare"), url("/fonts/BankerSquare-Regular.otf") format("opentype");
  }
  
  @font-face {
    font-family: "BankerSquare";
    font-style: normal;
    font-weight: bold;
    src: local("BankerSquare"), url("/fonts/BankerSquare-Bold.otf") format("opentype");
  }