body {
    @apply font-body;
}

h1, h2, h3 { 
    @apply font-sans ;
}

h1 {
    @apply text-4xl tracking-wider mb-8;

}

@screen md {
    h1 {
        @apply text-5xl;
    }
}

h2 {
    @apply text-3xl mb-4;
}

h3 {
    @apply text-2xl;
}

p {
    @apply text-sm;
}

.content p {
    @apply mt-4;
}